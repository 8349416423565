<template lang="pug">
.new-fish-order.flex.column.overflow-hidden
  dd-button(
    @click.native="showPreview"
    type="primary")
    .content.flex.align-center.justify-center
      span 出單預覽
      md-icon(style="color: white;") file_download_done
  .sum-info.flex.align-center.justify-center
    .fishing-money.flex.column
      p 釣魚
      p.amount {{ this.fishMoney }}
    .operator.flex.align-center
      p +
    .fishing-money.flex.column
      p 商品
      p.amount 0
    .operator.flex.align-center
      p =
    .fishing-money.flex.column
      p 總金額
      p.amount {{ this.totalMoney }}
  .contents.flex.column.overflow-overlay.fill(v-if="tab === 1")
    .infos.flex.column.p-8.gap-8.fill.overflow-hidden
      .inputs.flex.column.gap-8.fill.overflow-overlay.gap-20
        .contents.flex.column
          .fish-content.flex
            .fish-text.flex.align-center 時數
            .hour-content.flex.align-center.justify-center
              md-icon(@click.native="minusHour") remove_circle
              p {{ hour }}
              md-icon(@click.native="addHour") add_circle
          .fish-content.flex
            .fish-text.flex.align-center 竿數
            .hour-content.flex.align-center.justify-center
              md-icon(@click.native="minusCount") remove_circle
              p {{ count }}
              md-icon(@click.native="addCount") add_circle
          .fish-content.flex
            .fish-text.flex.align-center 緩衝時間
            .buffer-content.flex.align-center.justify-center
              md-field
                md-select(v-model="bufferTime")
                  md-option(v-for="time in bufferTimes", :key="time", :value="time") {{ time }}分鐘
          //- .fish-content.flex
          //-   .fish-text.flex.align-center 折扣設定
          //-   .md-layout-item.discount-content.flex.justify-center
          //-     md-field
          //-       md-select(v-model="discountType" placeholder="Select")
          //-         md-option(value="off10") 9折
          //-         md-option(value="off15") 85折
          //-         md-option(value="-100") -100
          template(v-if="localContinueOrder")
            .fish-content.flex
              .fish-text.flex.align-center 續單時間
              .value.flex.align-center.gap-8
                span {{ continueMinute }} 分鐘
                .link-text.text-09rem(@click="showContinueDialog = true") 續單來源

        //- .desc 備注
        //- dd-button(@click.native="upload") 上傳圖片
        //- input(
        //-   accept="image/*"
        //-   type="file", ref="upload",
        //-   @input="changeFile")
        //- img.preview-img(v-if="previewURL !== ''", :src="previewURL")
        //- textarea.textarea.p-20(rows="3", v-model="note")
  .contents.flex.column.fill.overflow-overlay(v-if="tab === 2")
  .ops.flex.align-center
    dd-button.fill(@click.native="tab=1", :tab-button="tab === 1") 釣魚
    dd-button.fill(@click.native="tab=2", :tab-button="tab === 2") 商品
  md-dialog(:md-active.sync="showDialog" :md-fullscreen="false")
    md-dialog-title 訂單資訊
    md-dialog-content
      .info.flex.column.justify-center.font-size-14
        p 時數 {{ hour }}
        p 時間 {{ startTimeStr }} - {{ endTimeStr }}
        p 金額 {{ totalMoney }}
      .payment-status.flex.align-center
        p 收款狀態
        md-radio(v-model="payment" :value="true") 已收
        md-radio(v-model="payment" :value="false") 未收
      .payment-status.flex.align-center.gap-8(v-if="payment")
        p 收款金額
        input(v-model="paidAmount", type="number", min="0")
    md-dialog-actions.flex.justify-end
      md-button.md-primary(@click="showDialog = false") 關閉
      md-button.md-primary(@click="save") 出單
  md-dialog(:md-active.sync="showContinueDialog" :md-fullscreen="false")
    md-dialog-title 訂單資訊
    md-dialog-content
      .info.flex.column.justify-center.font-size-14
        p 保留訂單編號 {{ localContinueOrder ? localContinueOrder.uuid.slice(0, 8) : '' }}
        p 訂單時間 {{ localContinueOrder ? $dateToString(localContinueOrder.start * 1000) : '' }}
    md-dialog-actions.flex.justify-end
      md-button.md-primary(@click="showContinueDialog = false") 關閉
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { getOptions, getBufferTimes, addReserve } from '@/api/reserve';
import { uploadImage, getImageThumbPath } from '@/api/image';
import GoodList from '@/components/GoodListInfo.vue';

export default {
  name: 'OrderList',
  data() {
    return {
      count: 1,
      hour: 3,
      bufferTime: 5,
      bufferTimes: [],
      discountType: 'Empty',
      fishMoney: 500,
      showDialog: false,
      startTime: 0,
      endTime: 0,
      startTimeStr: '',
      endTimeStr: '',
      payment: true,
      paidAmount: 0,

      priceMap: {},
      maxHour: 3,
      minHour: 3,

      imageID: undefined,
      note: '',
      continueMinute: 0,
      continueID: '',
      showContinueDialog: false,

      localContinueOrder: undefined,
      tab: 1,
    };
  },
  computed: {
    ...mapGetters(['continueOrder']),
    totalMoney() {
      return this.fishMoney + 0;
    },
    previewURL() {
      return this.imageID ? getImageThumbPath(this.imageID) : '';
    },
  },
  components: {
    List: GoodList,
  },
  watch: {
    discountType() {
      // 根據不同的折扣更新價錢
      console.log(this.discountType);
    },
  },
  methods: {
    ...mapMutations(['setHeaderTitle', 'setContinueOrder']),
    showPreview() {
      this.getCurrentTime();
      this.paidAmount = this.totalMoney;
      this.showDialog = true;
    },
    upload() {
      this.$refs.upload.click();
    },
    async changeFile() {
      if (this.$refs.upload.files.length < 1) {
        return;
      }
      this.$execWithLoading(async () => {
        const file = this.$refs.upload.files[0];
        const rsp = await uploadImage(file);
        this.imageID = rsp.data;
      });
    },
    updatePrice() {
      this.fishMoney = this.priceMap[this.hour] * this.count;
    },
    minusCount() {
      this.count -= 1;
      if (this.count < 1) {
        this.count = 1;
      }
      this.updatePrice();
    },
    addCount() {
      this.count += 1;
      this.updatePrice();
    },
    minusHour() {
      if (this.hour > this.minHour) {
        this.hour -= 1;
        this.updatePrice();
      }
    },
    addHour() {
      if (this.hour < this.maxHour) {
        this.hour += 1;
        this.updatePrice();
      }
    },
    show() {
      console.log(this.discountType);
    },
    getCurrentTime() {
      const now = new Date();
      const bufferTime = parseInt(this.bufferTime, 10);
      const start = new Date(now.getTime() + bufferTime * 60 * 1000);
      this.startTime = start;
      this.startTimeStr = this.$timeToString(start);

      const end = new Date(
        start.getTime() + this.hour * 3600 * 1000
        + (this.continueMinute ? this.continueMinute * 60 : 0) * 1000,
      );
      this.endTime = end;
      this.endTimeStr = this.$timeToString(end);
    },
    async save() {
      this.showDialog = false;
      await this.$execWithLoading(async () => {
        const start = parseInt(this.startTime.getTime() / 1000, 10);
        const end = parseInt(this.endTime.getTime() / 1000, 10);
        const notes = [];
        if (this.imageID || this.note !== '') {
          notes.push({
            photo_id: this.imageID,
            message: this.note,
            texts: [],
          });
        }
        const payment = {
          paid: parseInt(this.paidAmount, 10),
        };
        if (!this.payment) {
          payment.paid = 0;
        }
        const rsp = await addReserve(
          start, end, this.count, this.totalMoney, notes, payment, this.localContinueOrder?.uuid || '',
        );
        const uuid = rsp.data;
        this.printReserve(uuid, start, end, this.count, this.totalMoney, notes, this.localContinueOrder?.uuid || '');
        // this.$router.push('/orderlist');
        this.$router.push(`/order/${uuid}`);
      }, () => {
        this.$toast.error('開單失敗', {
          position: 'top-center',
          timeout: 2000,
          type: 'error',
          transition: 'fade',
        });
      });
    },
    async loadOptions() {
      const rsp = await getOptions();
      const options = rsp?.data || [];
      const priceMap = {};
      let minHour = 100;
      let maxHour = 0;
      options.forEach((opt) => {
        const hour = parseInt(opt.duration / 60, 10);
        if (hour > maxHour) {
          maxHour = hour;
        }
        if (hour < minHour) {
          minHour = hour;
        }
        priceMap[hour] = opt.price;
      });
      this.priceMap = priceMap;
      this.minHour = minHour;
      this.maxHour = maxHour;
      this.fishMoney = priceMap[this.hour];
    },
    async loadBufferTimes() {
      const rsp = await getBufferTimes();
      this.bufferTimes = rsp.data;
      this.bufferTime = rsp.data[0] || 0;
    },
    async loadSettings() {
      return Promise.all(
        this.loadOptions(),
        this.loadBufferTimes(),
      );
    },
    async loadData() {
      await this.$execWithLoading(async () => {
        await this.loadSettings();

        this.localContinueOrder = this.continueOrder;
        this.setContinueOrder(undefined);
        if (this.localContinueOrder) {
          this.continueMinute = parseInt(
            (this.localContinueOrder.end - this.localContinueOrder.actual_end) / 60, 10,
          ) + 1;
          this.continueID = this.localContinueOrder.uuid.slice(0, 8);
        }
      });
    },
    async printReserve(uuid, start, end, count, totalMoney, notes, continueUUID) {
      try {
        console.log({
          start, end, count, totalMoney, notes, continueUUID,
        });
        await this.init();
        await cordova.plugins.SummiPlugin.printText(
          `訂單編號 ${uuid.substr(0, 6)}\n\n`, 30, true, true,
        );

        const content = this.getContent(start, end, count, totalMoney, notes, continueUUID);
        await this.init();
        await cordova.plugins.SummiPlugin.printText(
          content, 24, true, false,
        );
      } catch (e) {
        console.log(e);
      }
    },
    getContent(start, end, count, totalMoney) {
      const lines = [];
      lines.push(`開始時間: ${this.$dateToString(start * 1000)} ${this.$timeToString(start * 1000)}`);
      lines.push(`結束時間: ${this.$dateToString(end * 1000)} ${this.$timeToString(end * 1000)}`);
      lines.push(`竿數: ${count}`);
      lines.push(`總金額: ${totalMoney}`);
      return `${lines.join('\n')}\n\n\n\n\n`;
    },
    async init() {
      await cordova.plugins.SummiPlugin.connectPrinter();
      console.log('init success');
    },
  },
  mounted() {
    this.setHeaderTitle('新增釣魚單');
    this.loadData();
  },
};
</script>
<style lang="scss" scoped>

.new-fish-order{
  overflow: hidden;
  .sum-info{
    height: 50px;
    background-color: aquamarine;
    justify-content: space-around;
    p{
      text-align: center;
    }
    .amount{
      font-size: 16px;
      font-weight: 600;
    }
    .operator{
      font-size: 20px;
      font-weight: 600;
    }
  }
  .title{
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .fish-content{
    padding: 0 20px;
    .fish-text{
      flex: 0 1 100px;
      font-size: 18px;
    }
    .hour-content, .buffer-content{
      flex: 1;
      .md-icon{
        margin: 10px 20px;
        padding: 12px;
      }
      p{
        font-size: 22px;
        font-weight: 600;
      }

    }
    .discount-content{
      // width: 100px;
      flex: 1 1 auto;
    }
  }
  .list-container{
    flex: 1;
    overflow: auto;
    overflow: overlay;
  }
  .preview-img{
    height: 120px;
    width: 120px;
    span{
      font-size: 16px;
    }
  }
}
.payment-status{
  .md-radio{
    margin-left: 10px;
  }
}
.textarea {
  font-size: 20px;
}
input[type=file] {
  display: none;
}
input[type=number] {
  padding: 4px;
}
</style>
