<template lang="pug">
.list.flex.column
  //- menu-icon(@click="getOrderData(123)") menu
  md-list.md-list
    template(v-for="(item, index) in orderData")
      md-list-item.list-item.flex(@click="show(index)")
        img(src='/m.jpeg')
        .info.flex.column.justify-center.align-center
          span {{item.orderNumber}}
          span {{ item.startTime }} - {{item.endTime}}
        p.currentStatus
          span(v-if="item.status === 0") 在場
          span(v-else-if="item.status === 1") 應離場
          span(v-else-if="item.status === 2") 已離場
          span(v-else) 未知 {{item.status}}
      md-divider

</template>

<script>
import { mapGetters } from 'vuex';
import Menu from './Menu.vue';

export default {
  data() {
    return {
      orderData:
        [
          {
            orderNumber: '20220704-001',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 0,
          },
          {
            orderNumber: '20220704-002',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 1,
          },
          {
            orderNumber: '20220704-003',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 1,
          },
          {
            orderNumber: '20220704-004',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 2,
          },
          {
            orderNumber: '20220704-005',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 1,
          },
          {
            orderNumber: '20220704-006',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 1,
          },
          {
            orderNumber: '20220704-007',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 2,
          },
          {
            orderNumber: '20220704-008',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 2,
          },
          {
            orderNumber: '20220704-009',
            startTime: '16:04',
            endTime: '19:04',
            imgPath: '/m.jpeg',
            status: 4,
          },
        ],
    };
  },
  computed: {
    ...mapGetters(['showMenu', 'headerTitle']),
  },
  components: {
    'pos-menu': Menu,
  },
  methods: {
    getOrderData(ev, item) {
      console.log('HERE');
      console.log(ev);
      console.log(item);
    },
    show(i) {
      console.log('show');
      console.log(i);
    },
    getStatus(s) {
      if (s === 0) {
        return '在場';
      }
      if (s === 1) {
        return '應離場';
      }
      return '已離場';
    },
  },
};
</script>

<style lang="scss" scoped>
.md-list {
  padding-bottom: 80px;
  img{
    width: 60px;
    height: 60px;
    border-radius: 10%;
  }
}
.list-item {
  img {
    padding: 4px;
  }
  .currentStatus {
    flex: 0 0 60px;
  }
}
</style>
